// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-email-tsx": () => import("./../../src/pages/email.tsx" /* webpackChunkName: "component---src-pages-email-tsx" */),
  "component---src-pages-google-calendar-tsx": () => import("./../../src/pages/google-calendar.tsx" /* webpackChunkName: "component---src-pages-google-calendar-tsx" */),
  "component---src-pages-google-drive-image-tsx": () => import("./../../src/pages/google-drive-image.tsx" /* webpackChunkName: "component---src-pages-google-drive-image-tsx" */),
  "component---src-pages-google-maps-tsx": () => import("./../../src/pages/google-maps.tsx" /* webpackChunkName: "component---src-pages-google-maps-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-phone-call-tsx": () => import("./../../src/pages/phone-call.tsx" /* webpackChunkName: "component---src-pages-phone-call-tsx" */),
  "component---src-pages-sms-tsx": () => import("./../../src/pages/sms.tsx" /* webpackChunkName: "component---src-pages-sms-tsx" */),
  "component---src-pages-telegram-tsx": () => import("./../../src/pages/telegram.tsx" /* webpackChunkName: "component---src-pages-telegram-tsx" */),
  "component---src-pages-twitter-tsx": () => import("./../../src/pages/twitter.tsx" /* webpackChunkName: "component---src-pages-twitter-tsx" */),
  "component---src-pages-whatsapp-tsx": () => import("./../../src/pages/whatsapp.tsx" /* webpackChunkName: "component---src-pages-whatsapp-tsx" */)
}

